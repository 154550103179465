export const signs = [
  'Aries ♈',
  'Taurus ♉️',
  'Gemini ♊️',
  'Cancer ♋️',
  'Leo ♌️',
  'Virgo ♍️',
  'Libra ♎️',
  'Scorpio ♏️',
  'Sagittarius ♐️',
  'Capricorn ♑️',
  'Aquarius ♒️',
  'Pisces ♓️'
]
