import React, { useEffect, useState } from 'react'
import { add, endOfWeek, format, startOfWeek } from 'date-fns'
import randomEmoji from '@alexfrankcodes/random-emoji'

import { Button } from '../../../components/Button'
import { Card } from '../../../components/Card'
import { Table } from '../../../components/Table'
import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { CopyAndPaste } from '..'

import { signs } from './constants'

import Next from './assets/next.svg'
import Prev from './assets/prev.svg'
import Emojis from './assets/emojis.svg'

import styles from './styles.module.scss'
import { calendarTool } from '../../tools'

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Date' },
  { name: 'Calendar' },
  { name: 'Copy and paste' },
  { name: 'Emoji' },
  { name: 'Memes' }
]

const table = [
  ['Date range', 'Star sign', 'Symbol', 'Related emojis'],
  ['March 21 – April 19', 'Aries', '♈️', '🐐'],
  ['April 20 – May 20', 'Taurus', '♉️', '🐂'],
  ['May 21 – June 20', 'Gemini', '♊️', '👯‍♀️'],
  ['June 21 – July 22', 'Cancer', '♋️', '🦀'],
  ['July 23 – August 22', 'Leo', '♌️', '🦁'],
  ['August 23 – September 22', 'Virgo', '♍️', '👩‍🔬 👩‍🎓 👷‍♀️'],
  ['September 23 – October 22', 'Libra', '♎️', '⚖️ 👩‍⚖ ️👨‍⚖ ️'],
  ['October 23 – November 21', 'Scorpio', '♏️', '🦂'],
  ['November 22 – December 21', 'Sagittarius', '♐️', '🐴 🏹'],
  ['December 22 – January 19', 'Capricorn', '♑️', '🐟 + 🐐 = ?'],
  ['January 20 – February 18', 'Aquarius', '♒️', '🚰 💧'],
  ['February 19 – March 20', 'Pisces', '♓️', '🐟 🐠 🐡 🦈']
]

type Horoscope = {
  sign: string
  emoji: [string, string, string]
}

const EmojiHoroscope: React.FC = () => {
  const [week, setWeek] = useState(0)
  const [horoscope, setHoroscope] = useState<Horoscope[]>([])

  const weekTitleByNumber: Record<number, string> = {
    '-1': 'The past',
    0: 'This week',
    1: 'Next week',
    2: 'Future'
  }

  const currentWeekTitle = (): [string, string] => {
    const actualWeek = new Date()
    const a = format(startOfWeek(actualWeek), 'd MMMM y')
    const b = format(endOfWeek(actualWeek), 'd MMMM y')
    return [a, b]
  }

  const nextWeekTitle = (): [string, string] => {
    const actualWeek = add(new Date(), { weeks: 1 })
    const a = format(startOfWeek(actualWeek), 'd MMMM y')
    const b = format(endOfWeek(actualWeek), 'd MMMM y')
    return [a, b]
  }

  const weekSubtitleByNumber: Record<number, string | [string, string]> = {
    '-1': 'Don\'t worry \'bout it!',
    0: currentWeekTitle(),
    1: nextWeekTitle(),
    2: 'Too hazy, can\'t tell.'
  }

  useEffect(() => {
    if (week === -1 || week === 2) {
      setHoroscope([])
      return
    }

    const actualWeek = week === 0 ? new Date() : add(new Date(), { weeks: 1 })
    const startOfTheWeekTimestamp = startOfWeek(actualWeek).getTime().toString()

    const existingHoroscope = localStorage.getItem(startOfTheWeekTimestamp)
    if (existingHoroscope) {
      const parsedExistingHoroscope = JSON.parse(existingHoroscope)
      setHoroscope(parsedExistingHoroscope)
    } else {
      const newHoroscope = signs.map<Horoscope>(sign => ({
        sign,
        emoji: [randomEmoji.random(1), randomEmoji.random(1), randomEmoji.random(1)]
      }))
      setHoroscope(newHoroscope)
      localStorage.setItem(startOfTheWeekTimestamp, JSON.stringify(newHoroscope))
    }
  }, [week])

  return (
    <CopyAndPaste tags={tags} moreTools={[calendarTool]} subtitleClassName={styles.subtitle} titleClassName={styles.title}>
      <div className={styles.row}>
        <Button className={styles.button} disabled={week < 0} onClick={() => setWeek(Math.max(week - 1, -1))}>
          <Prev />
          <span>Prev</span>
        </Button>
        <div className={styles.week}>
          <b className={styles['week-title']}>{weekTitleByNumber[week]}</b>
          <small className={styles['week-subtitle']}>
            {Array.isArray(weekSubtitleByNumber[week])
              ? (<><span>{weekSubtitleByNumber[week][0]} - </span><span>{weekSubtitleByNumber[week][1]}</span></>)
              : weekSubtitleByNumber[week]
            }
          </small>
        </div>
        <Button className={styles.button} onClick={() => setWeek(Math.min(week + 1, 2))}>
          <span>Next</span>
          <Next />
        </Button>
      </div>
      <TextWithImage
        subtitle={
          <>
            Let Font Maker calculate your horoscope to reveal what your future holds.
            Your horoscope is converted into emoji form so you can easily copy and paste it to share online.
          </>
        }
        image={<Emojis />}
        style={{ marginTop: 64 }}
      />
      {week === -1 ? <p className={styles['no-emoji']}>Let go of the past. Look to the future.</p> : null}
      {week === 2 ? <p className={styles['no-emoji']}>Too hazy, can't tell.</p> : null}
      <VariantsContainer className={styles['variants-container']}>
        {horoscope.map((sign, index) => <Card className={styles.card} key={index} title={sign.sign} text={sign.emoji.join(' ')} textFontSize={32} textAlignCenter small />)}
      </VariantsContainer>
      <div className={styles['gaps-container']}>
        <TextGroup title='How to interpret your emoji horoscope'>
          <p>
            Having trouble understanding your emoji horoscope? Here’s a brief guide:
          </p>
          <p>
            First, determine your star sign according to your date of birth.
          </p>
        </TextGroup>
        <Table firstRowIsHeaders>
          {table}
        </Table>
        <div className={styles.text}>
          <p>
            Once you’ve found your star sign, check your horoscope using the tool above.
            Then, interpret the resulting emojis. We’ll use an example to explain the process.
          </p>
          <p>Let’s say you’re a Libra and your horoscope was:</p>
          <p>🥔 🤬 📞</p>
          <p>Now, this could mean a number of things.</p>
        </div>
        <TextGroup subtitle='There are literal interpretations:'>
          You’ll receive an angry potato-related phone call.
        </TextGroup>
        <TextGroup subtitle='There are symbolic interpretations:'>
          Here, the potato stands for hard work. The angry face is a father figure.
          The phone is symbolic of society’s unhealthy dependence on technology.
          You need to spend less time working and more quality face-to-face time with your parents.
        </TextGroup>
        <TextGroup subtitle='There are emotional interpretations:'>
          These symbols make you angry for no specific reason. You hate fries, swearing, and telephones. This means you’re going to have a terrible week.
        </TextGroup>
        <TextGroup>
          The only way of truly making the correct interpretation is to wait until after it has already happened.
          Once the week has passed, you can work out what your emojis meant. Hindsight is 20/20.
        </TextGroup>
      </div>
    </CopyAndPaste>
  )
}

export default EmojiHoroscope
